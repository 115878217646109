import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import UserInputForm from './Components2/UserForm/UserForm';
import UserList from './Components2/UserData/UserList';
import AddUser from './FormikForms/AddUser';
import Print from './FormikForms/antDForm';


function App() {
    return <Print/>
}
export default App;




