import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./UserDataRedux/UserReducer/UserSlice";


export const store =  configureStore({
    reducer: {
         Users: UserSlice,
        

    },
    
})