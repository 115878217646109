import { createSlice} from "@reduxjs/toolkit";




const initialState = [
    {
        name: "Muhammad Ahmed",
        age: 28
    },
    {
        name: "Aleena Ahmed",
        age: 25
    },
    {
        name: "Sheryar",
        age: 1
    }
]



export const UserSlice = createSlice({
    name: "Users",
    initialState,
    reducers: {
            addUser: (state , action) => {
                state.push(action.payload)
            },
            deleteUser: (state, action) => {
                
            }

    }
});


export const { addUser, deleteUser } = UserSlice.actions

export default UserSlice.reducer